import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aaaefbe2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal_animation" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "modal-animation-inner" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                onClick: $setup.close,
                id: "closeModalAwesome",
                icon: ['fas', 'times-circle']
              }, null, 8, ["onClick"]),
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 512), [
              [_vShow, $props.modalActive]
            ])
          ]),
          _: 3
        })
      ], 512), [
        [_vShow, $props.modalActive]
      ])
    ]),
    _: 3
  }))
}